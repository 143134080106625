@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
.burger-icon {
  display: none;
  padding: 1rem;
  z-index: 5;
}
.burger-icon:hover {
  cursor: pointer;
}
.burger-bar {
  width: 2.5rem;
  height: calc(0.5rem / 3);
  margin: 0.5rem 0;
  background-color: var(--purple-light);
}
.burger-active {
  z-index: 3;
}
.burger-active .burger-bar:nth-child(1) {
  -webkit-animation: bar1 0.5s forwards;
          animation: bar1 0.5s forwards;
}
.burger-active .burger-bar:nth-child(2) {
  opacity: 0;
  transition: opacity 0.5s, background-color 0.5s;
}
.burger-active .burger-bar:nth-child(3) {
  -webkit-animation: bar3 0.5s forwards;
          animation: bar3 0.5s forwards;
}

@-webkit-keyframes bar1 {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(0.65rem) rotate(45deg);
  }
}

@keyframes bar1 {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(0.65rem) rotate(45deg);
  }
}
@-webkit-keyframes bar3 {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(-0.65rem) rotate(-45deg);
  }
}
@keyframes bar3 {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(-0.65rem) rotate(-45deg);
  }
}

@media (max-width: 768px) {
  .burger-icon {
    display: block;
  }
}

/* Icon */
i {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: var(--text);
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid var(--text);
  border-radius: 15px;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: var(--bg);
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  box-shadow: 0 0 5px var(--text);
}

.react-toggle--checked .react-toggle-track {
  background-color: var(--bg);
}

/* .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--color-hover);
} */

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 5px 5px #0099e0;
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--nav-height);
  width: 100%;
  background: var(--bg-nav);
  color: var(--text);
  z-index: 10;
}
.navbar-nav {
  display: flex;
  text-align: center;
  flex: 1 1;
  justify-content: space-around;
}
.nav-item {
  margin: 2rem 0;
  cursor: pointer;
  transition: color 0.5s, transform 0.5s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.nav-item:hover {
  color: var(--color-hover);
  -webkit-animation: text-rotate 0.5s;
          animation: text-rotate 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@media (max-width: 768px) {
  .navbar {
    justify-content: flex-end;
  }
  .navbar-nav {
    display: none;
  }
}

.sidebar {
  position: fixed;
  z-index: 9;
  top: var(--nav-height);
  right: 0;
  height: calc(100vh - var(--nav-height));
  width: 0%;
  background-color: var(--purple-light);
  color: var(--purple1);
  transition: width 0.5s;
  overflow: hidden;
}
.sidebar-nav {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
}
.side-item {
  margin: 1rem 0;
  cursor: pointer;
  transition: color 0.5s, transform 0.5s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.side-item:hover {
  -webkit-animation: text-rotate 0.5s;
          animation: text-rotate 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

form#contact-form {
  width: 80%;
  margin: auto;
}
form#contact-form input,
textarea#contact-text {
  display: block;
  margin: 1rem 0;
  width: 100%;
}

footer {
  text-align: center;
  color: whitesmoke;
  height: var(--footer-height);
  background: var(--bg-nav);
  color: var(--text);
  -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 10%, 0 0%);
          clip-path: polygon(0 100%, 100% 100%, 100% 10%, 0 0%);
}

#socials {
  display: flex;
  justify-content: center;
}
#socials > div {
  margin: 0 0.5rem 0 0.5rem;
}
#socials img {
  width: 3rem;
}

header {
  padding: 1rem;
  padding-bottom: 3.5rem;
  min-height: calc(100vh - 70px);
  text-align: center;
  background: var(--bg-nav);
  -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 65%, 50% 100%, 0 65%, 0 0);
          clip-path: polygon(50% 0%, 100% 0, 100% 65%, 50% 100%, 0 65%, 0 0);
}
.logo {
  width: 20%;
  -webkit-filter: hue-rotate(256deg);
          filter: hue-rotate(256deg);
}
.logo:hover {
  -webkit-animation: color-rotate 1s;
          animation: color-rotate 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  color: #8995c9;
}
@-webkit-keyframes color-rotate {
  from {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(360deg);
            filter: hue-rotate(360deg);
  }
}
@keyframes color-rotate {
  from {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(360deg);
            filter: hue-rotate(360deg);
  }
}
@media (max-width: 768px) {
  .logo {
    width: 35%;
  }
}

@media (max-width: 600px) {
  header {
    -webkit-clip-path: none;
            clip-path: none;
  }
  .logo {
    width: 50%;
  }
}

.arrows {
  display: block;
  width: 5rem;
  margin: auto;
}
.arrow {
  height: 2rem;
  background-color: var(--text);
  -webkit-clip-path: polygon(50% 90%, 100% 0, 100% 10%, 50% 100%, 0 10%, 0 0);
          clip-path: polygon(50% 90%, 100% 0, 100% 10%, 50% 100%, 0 10%, 0 0);
}
.arrow:nth-child(1) {
  -webkit-animation: arrow1 2s linear infinite;
          animation: arrow1 2s linear infinite;
}
.arrow:nth-child(2) {
  -webkit-animation: arrow2 2s linear infinite;
          animation: arrow2 2s linear infinite;
}
.arrow:nth-child(3) {
  -webkit-animation: arrow3 2s linear infinite;
          animation: arrow3 2s linear infinite;
}
@-webkit-keyframes arrow1 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes arrow1 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes arrow2 {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes arrow2 {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}
@-webkit-keyframes arrow3 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes arrow3 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#projects-container {
  width: 100%;
  margin: auto;
  padding: 1rem;
}

.project {
  width: 75%;
  position: relative;
  margin: 1.5rem auto;
  background: transparent;
}
.project:hover .project-overlay {
  box-shadow: 0 0 10px var(--text);
  opacity: 0.9;
}
.project:hover .project-img {
  width: 100%;
  height: 100%;
}
.project-img-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-img {
  width: 95%;
  height: 95%;
  transition: width 0.3s, height 0.3s;
}
.project-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: normal;
  font-size: 0.7rem;
  top: 0;
  left: 0;
  opacity: 1;
  padding: 1rem;
  height: 100%;
  width: 100%;
  background-color: var(--bg);
  opacity: 0;
  transition: opacity 0.5s, box-shadow 0.5s;
}
@media (max-width: 768px) {
  .project {
    width: 85%;
  }
}

@media (max-width: 600px) {
  .project-overlay {
    font-size: 0.6rem;
  }
}

#skills {
  margin-right: auto;
  margin-left: auto;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
}

.skill {
  margin: 8px;
}
.progress-value {
  padding: 8px;
  color: var(--purple2);
  height: 100%;
  z-index: 1;
  background-color: var(--purple-light);
  border-radius: 0.2rem;
}
@-webkit-keyframes progress {
  from {
    flex: 0 1;
  }
  to {
    flex: 9 1;
  }
}
@keyframes progress {
  from {
    flex: 0 1;
  }
  to {
    flex: 9 1;
  }
}

:root {
  --gray0: #f8f8f8;
  --gray1: #dbe1e8;
  --gray2: #b2becd;
  --gray3: #6c7983;
  --gray4: #454e56;
  --gray5: #2a2e35;
  --gray6: #222222;
  --purple0: #3a3a4d;
  --purple1: #23232e;
  --purple2: #17171f;
  --purple-light: #6868b6;
  --nav-height: 70px;
  --line-height: 1.7rem;
  --footer-height: 360px;

  --bg: var(--purple1);
  --bg-two: var(--purple0);
  --bg-three: var(--bg-two);
  --color-hover: var(--purple-light);
  --bg-nav: linear-gradient(
    to right,
    var(--purple1),
    var(--purple2),
    var(--purple1)
  );
  --text: var(--gray2);
}

code {
  font-family: "Montserrat", sans-serif;
}
* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
.light {
  --bg: var(--gray1);
  --bg-two: var(--gray4);
  --bg-three: var(--gray2);
  --color-hover: var(--purple0);
  --bg-nav: var(--gray2);
  --text: var(--gray6);
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  background-color: #23232e;
  background-color: var(--bg);
  color: #b2becd;
  color: var(--text);
  transition: background 0.5s ease-in-out, color 1s ease-in-out;
  line-height: 1.7rem;
  line-height: var(--line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  width: 0.25rem;
}
body::-webkit-scrollbar-track {
  background: #23232e;
  background: var(--bg);
}

body::-webkit-scrollbar-thumb {
  background: #b2becd;
  background: var(--text);
  border-radius: 10px;
}
button,
[button] {
  padding: 0.5rem;
  border-radius: 0;
  outline: none;
  border: 1px solid #b2becd;
  border: 1px solid var(--text);
  background: transparent;
  color: #b2becd;
  color: var(--text);
  border-radius: 0;
  font-weight: bold;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
button:hover,
[button]:hover {
  cursor: pointer;
  background: #b2becd;
  background: var(--text);
  color: #23232e;
  color: var(--bg);
  transition: background 0.5s, color 0.5s;
  box-shadow: 0 0 5px #b2becd;
  box-shadow: 0 0 5px var(--text);
}
input,
textarea {
  background-color: #dbe1e8;
  background-color: var(--gray1);
  padding: 0.7rem;
  outline: none;
  border: 1px solid #b2becd;
  border: 1px solid var(--text);
}
input:focus,
textarea:focus {
  box-shadow: 0 0 5px #b2becd;
  box-shadow: 0 0 5px var(--text);
}
.marginv {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.margin {
  margin: 1rem;
}
.padding {
  padding: 1rem;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
ul {
  list-style: none;
}
a {
  color: currentColor;
  text-decoration: none;
}
section {
  width: 75%;
  margin: auto;
}
.section-title {
  text-align: center;
}

.not-footer {
  margin: 0;
  padding: 0;
  min-height: calc(100vh - 360px);
  min-height: calc(100vh - var(--footer-height));
}

@-webkit-keyframes text-rotate {
  from {
    transform: rotate(-3deg);
  }
  to {
    transform: rotate(3deg);
  }
}

@keyframes text-rotate {
  from {
    transform: rotate(-3deg);
  }
  to {
    transform: rotate(3deg);
  }
}

@media (max-width: 768px) {
  body {
    font-size: 0.8rem;
  }
  section {
    width: 90%;
  }
}

@media (max-width: 600px) {
}

