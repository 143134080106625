.burger-icon {
  display: none;
  padding: 1rem;
  z-index: 5;
}
.burger-icon:hover {
  cursor: pointer;
}
.burger-bar {
  width: 2.5rem;
  height: calc(0.5rem / 3);
  margin: 0.5rem 0;
  background-color: var(--purple-light);
}
.burger-active {
  z-index: 3;
}
.burger-active .burger-bar:nth-child(1) {
  animation: bar1 0.5s forwards;
}
.burger-active .burger-bar:nth-child(2) {
  opacity: 0;
  transition: opacity 0.5s, background-color 0.5s;
}
.burger-active .burger-bar:nth-child(3) {
  animation: bar3 0.5s forwards;
}

@keyframes bar1 {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(0.65rem) rotate(45deg);
  }
}
@keyframes bar3 {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(-0.65rem) rotate(-45deg);
  }
}

@media (max-width: 768px) {
  .burger-icon {
    display: block;
  }
}
