.sidebar {
  position: fixed;
  z-index: 9;
  top: var(--nav-height);
  right: 0;
  height: calc(100vh - var(--nav-height));
  width: 0%;
  background-color: var(--purple-light);
  color: var(--purple1);
  transition: width 0.5s;
  overflow: hidden;
}
.sidebar-nav {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
}
.side-item {
  margin: 1rem 0;
  cursor: pointer;
  transition: color 0.5s, transform 0.5s;
  width: fit-content;
}
.side-item:hover {
  animation: text-rotate 0.5s;
  animation-fill-mode: forwards;
}
