@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

:root {
  --gray0: #f8f8f8;
  --gray1: #dbe1e8;
  --gray2: #b2becd;
  --gray3: #6c7983;
  --gray4: #454e56;
  --gray5: #2a2e35;
  --gray6: #222222;
  --purple0: #3a3a4d;
  --purple1: #23232e;
  --purple2: #17171f;
  --purple-light: #6868b6;
  --nav-height: 70px;
  --line-height: 1.7rem;
  --footer-height: 360px;

  --bg: var(--purple1);
  --bg-two: var(--purple0);
  --bg-three: var(--bg-two);
  --color-hover: var(--purple-light);
  --bg-nav: linear-gradient(
    to right,
    var(--purple1),
    var(--purple2),
    var(--purple1)
  );
  --text: var(--gray2);
}

code {
  font-family: "Montserrat", sans-serif;
}
* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
.light {
  --bg: var(--gray1);
  --bg-two: var(--gray4);
  --bg-three: var(--gray2);
  --color-hover: var(--purple0);
  --bg-nav: var(--gray2);
  --text: var(--gray6);
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  background-color: var(--bg);
  color: var(--text);
  transition: background 0.5s ease-in-out, color 1s ease-in-out;
  line-height: var(--line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  width: 0.25rem;
}
body::-webkit-scrollbar-track {
  background: var(--bg);
}

body::-webkit-scrollbar-thumb {
  background: var(--text);
  border-radius: 10px;
}
button,
[button] {
  padding: 0.5rem;
  border-radius: 0;
  outline: none;
  border: 1px solid var(--text);
  background: transparent;
  color: var(--text);
  border-radius: 0;
  font-weight: bold;
  width: fit-content;
}
button:hover,
[button]:hover {
  cursor: pointer;
  background: var(--text);
  color: var(--bg);
  transition: background 0.5s, color 0.5s;
  box-shadow: 0 0 5px var(--text);
}
input,
textarea {
  background-color: var(--gray1);
  padding: 0.7rem;
  outline: none;
  border: 1px solid var(--text);
}
input:focus,
textarea:focus {
  box-shadow: 0 0 5px var(--text);
}
.marginv {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.margin {
  margin: 1rem;
}
.padding {
  padding: 1rem;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
ul {
  list-style: none;
}
a {
  color: currentColor;
  text-decoration: none;
}
section {
  width: 75%;
  margin: auto;
}
.section-title {
  text-align: center;
}

.not-footer {
  margin: 0;
  padding: 0;
  min-height: calc(100vh - var(--footer-height));
}

@keyframes text-rotate {
  from {
    transform: rotate(-3deg);
  }
  to {
    transform: rotate(3deg);
  }
}

@media (max-width: 768px) {
  body {
    font-size: 0.8rem;
  }
  section {
    width: 90%;
  }
}

@media (max-width: 600px) {
}
