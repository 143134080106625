.arrows {
  display: block;
  width: 5rem;
  margin: auto;
}
.arrow {
  height: 2rem;
  background-color: var(--text);
  clip-path: polygon(50% 90%, 100% 0, 100% 10%, 50% 100%, 0 10%, 0 0);
}
.arrow:nth-child(1) {
  animation: arrow1 2s linear infinite;
}
.arrow:nth-child(2) {
  animation: arrow2 2s linear infinite;
}
.arrow:nth-child(3) {
  animation: arrow3 2s linear infinite;
}
@keyframes arrow1 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes arrow2 {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes arrow3 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
