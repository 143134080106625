.skill {
  margin: 8px;
}
.progress-value {
  padding: 8px;
  color: var(--purple2);
  height: 100%;
  z-index: 1;
  background-color: var(--purple-light);
  border-radius: 0.2rem;
}
@keyframes progress {
  from {
    flex: 0;
  }
  to {
    flex: 9;
  }
}
